<template>
   <div id="contacto" class="contactanos">
       <div class="contactanos-cont">
           <div class="c-l">
               <h2>Contáctanos</h2>
               <p>¿Quieres más información?¿Cotizaciones?</p>
               <form action="" class="form">
                <div class="form-item">
                    <label>Nombre completo:</label>
                    <input type="text">
                </div>
                <div class="form-item">
                    <label>Especialidad requerida:</label>
                    <div class="checks">
                        <input id="cb5" type="checkbox" class="tgl tgl-flip">
                        <label data-tg-on="Construcción" for="cb5" class="tgl-btn"></label>
                        <input id="cb5" type="checkbox" class="tgl tgl-flip">
                        <label data-tg-on="Desarrollo" for="cb5" class="tgl-btn">
                        </label><input id="cb5" type="checkbox" class="tgl tgl-flip">
                        <label data-tg-on="Residencial" for="cb5" class="tgl-btn"></label>
                    </div>
                </div>
                <div class="form-item">
                    <label>Correo:</label>
                    <input type="text"></div>
                    <div class="form-item">
                        <label>Telefono:</label>
                        <input type="text">
                    </div>
                    <div class="form-item">
                        <label>Mensaje:</label>
                        <textarea name="" id="" cols="30" rows="10"></textarea>
                    </div>
                    <button class="contact-btn">Envíar</button>
                </form>
            </div>
            <div class="c-r">
                <div class="iframe">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3622.2131759754566!2d-107.40829259892428!3d24.78815290417729!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd0c053cf2527%3A0x4c5e7440dbb9afd6!2sMorelos%2C%2080170%20Culiac%C3%A1n%2C%20Sinaloa!5e0!3m2!1sen!2smx!4v1589061995533!5m2!1sen!2smx" width="100%" height="450" frameborder="0" allowfullscreen="allowfullscreen" aria-hidden="false" tabindex="0"></iframe>
                </div>
                <div class="cont-info">
                    <div class="ci_left">
                        <p>Centro Histórico, Culiacán Sinaloa.</p>
                        <p><img src="@/assets/svg/wp.svg" class="wp"> 6673408660</p>
                        <p class="social"><img src="@/assets/svg/sh_fb-y.svg" class="sh">
                        <img src="@/assets/svg/sh_ig-y.svg" class="sh">
                        <img src="@/assets/svg/sh_tw-y.svg" class="sh"></p>
                    </div>
                    <img src="@/assets/svg/logo_solid.svg" class="logo">
                </div>
            </div>
        </div>
        <SocialMenu v-if="!home" source="nosotros" />
    </div>
</template>
<script>
import SocialMenu from './SocialMenu';
export default {
    name: 'Contacto',
    components: {
        SocialMenu
    }
}
</script>
<style>
.contactanos-cont {
    display: flex;
    max-width: 1200px;
}
.contactanos{
    padding: 15rem 5rem 5rem 15rem;
    width: 100%;
    min-height: 100vh;
    background-color: #1f1f1f;
    background-image: url(../assets/img/bg-6.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.c-l {
    flex: 1;
    text-align: left;
}
.form {
    margin-top: 3rem;
    max-width: 25rem;
}
.form .form-item {
    display: flex;
    flex-direction: column;
}
.form .form-item label {
    color: #ccad41;
    margin: .5rem 0;
}
input {
    height: 1.5rem;
}
.checks {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}
.form .form-item label {
    color: #ccad41;
    margin: .5rem 0;
}
.tgl {
    display: none;
}
.tgl-flip+.tgl-btn:after {
    content: attr(data-tg-on);
    background: #d1bd76;
    color: #fff;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    padding: .51rem;
    color: #fff;
}


.contact-btn {
    background-color: #ccad41;
    padding: .5rem;
    border: none;
    margin-top: .5rem;
}
.c-r {
    flex: 1;
}
.c-r .iframe {
    border-bottom: 5px solid #ccad41;
}
.cont-info {
    display: flex;
    margin-top: 2rem;
    font-size: .8rem;
    text-align: right;
}
.cont-info .ci_left {
    padding-right: 1rem;
    flex: 1;
}
.wp {
    width: 1rem;
}
.cont-info .ci_left .social img {
    margin: .5rem 0 0 .5rem;
    stroke: #d1bd76;
}
.sh {
    width: 1.5rem;
}
.cont-info .logo {
    width: 7rem;
}
@media screen and (max-width: 990px) {
    
.contact-btn {
    margin-bottom: 5rem;
}
.contactanos-cont {
    flex-direction: column;
}
}
@media screen and (max-width: 1190px) {
.contactanos {
    padding: 10rem 5rem 5rem 5rem;
}
}
</style>
