<template>
  <div class="header">
    <router-link to="/"><div class="header-item">Inicio</div></router-link>
    <router-link to="/residencial"><a><div class="header-item">Residencial</div></a></router-link>
    <router-link to="/desarrollos"><a><div class="header-item">Desarrollos</div></a></router-link>
    <router-link to="/construccion"><a><div class="header-item">Construcción</div></a></router-link>
    <router-link to="/conocenos"><a><div class="header-item">Conócenos</div></a></router-link>
    <router-link to="/contacto"><a><div class="header-item">Contacto</div></a></router-link>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.header {
    z-index: 100;
    position: absolute;
    right: 3rem;
    top: 0;
    min-width: 17rem;
    height: 4rem;
    border-bottom: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    padding: 0 .5rem .5rem 0;
}
.header a {
    text-decoration: none;
    height: 100%;
    width: 100%;
    margin-right: 1rem;
}
.header-item {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
}
@media screen and (max-width: 900px){
  .header {
   width: 80%;
   flex-wrap: wrap;
  }
  .header a{
    width: 5rem;
    height: 50%;
  }
}
</style>
