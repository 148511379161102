import Vue from 'vue'
import Router from 'vue-router'
import Home from "@/components/Home.vue";
import Conocenos from "@/components/Conocenos.vue";
import Contacto from "@/components/Contacto.vue";
import Construccion from "@/components/Construccion.vue";
import Desarrollos from "@/components/Desarrollos.vue";
import Residencial from "@/components/Residencial.vue";

Vue.use(Router)

// route-level code splitting


export function createRouter () {
  return new Router({
    mode: 'history',
    routes: [
      { path: "/", name: "Inicio", component: Home },
      { path: "/conocenos", name: "Conocenos", component: Conocenos },
      { path: "/contacto", name: "Contacto", component: Contacto },
      { path: "/construccion", name: "Construccion", component: Construccion },
      { path: "/desarrollos", name: "Desarrollos", component: Desarrollos },
      { path: "/residencial", name: "Residencial", component: Residencial },
    ]
  })
}
