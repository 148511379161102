<template>
    <div class="social-menu">
        <div class="social_tri1"></div>
        <a v-if="getiURL(source)" :href="getiURL(source)">
            <img src="@/assets/svg/sh_ig.svg">
        </a>
        <a v-if="getfURL(source)" :href="getfURL(source)">
            <img src="@/assets/svg/sh_fb.svg">
        </a>
         <a v-if="getlURL(source)" :href="getlURL(source)">
            <img src="@/assets/svg/sh_ln.svg">
        </a>
            <div class="social_tri2">
        </div>
    </div>
</template>
<script>
export default {
    props: ['source'],
    methods: {
        getfURL (s){
            let url = '';
            switch(s) {
                case 'construccion':
                    url = 'https://www.facebook.com/atworkconstruccion';
                break;
                case 'desarrollo':
                    url = 'https://www.facebook.com/aTwork-desarrollos-108271750620561';
                break;
                case 'residencial':
                    url = 'https://www.facebook.com/aTworkResidencial';
                break;
            }
            return url;
        },
        getiURL (s){
            let url = '';
            switch(s) {
                case 'construccion':
                    url = 'https://www.instagram.com/atworkconstruccion/';
                break;
                case 'desarrollo':
                    url = 'https://www.instagram.com/atworkdesarrollos/';
                break;
                case 'residencial':
                    url = 'https://www.instagram.com/atworkresidencial/';
                break;
            }
            return url;
        },
        getlURL (s){
            let url = '';
            switch(s) {
                case 'nosotros':
                    url = 'https://www.linkedin.com/in/gabriel-ochoa-717942a8/';
                break;
            }
            return url;
        }
    }
}
</script>
<style>
  .social-menu {
    position: fixed;
    z-index: 100;
    left: 4rem;
    top: 35%;
    width: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #fff;
}
.social-menu .social_tri1 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3px 50px 3px;
    border-color: transparent transparent #fff transparent;
}
.social-menu img {
    width: 2.5rem;
    margin: 1rem 0;
}
.social-menu .social_tri2 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 3px 0 3px;
    border-color: #fff transparent transparent transparent;
}
@media screen and (max-width: 1190px) {
    .social-menu {
        display: none;
    }
}
</style>