<template>
<div>
    <div id="viviendas" class="residencial">
        <div class="residencial-content-bg"></div>
        <div class="residencial-content">
            <div class="residencial-title">
                <h2>Residencial</h2>
                <img src="@/assets/svg/section_icon_2.svg">
            </div>
            <p class="pharagrah">Nos especializamos en la construcción de casas a la medida.</p>
            <p class="pharagrah">Lo que significa que el cliente junto con nuestros arquitectos y diseñadores crean una vivienda espectacular que cumple con tosas las necesidades individuales junto con las de tu familia.</p>
            <p class="pharagrah">Utilizamos una metodología comprobada, que nos ha permitido llevar de 0 a 100 todos nuestros proyectos sin que se escape ninguna necesidad:</p>
            <div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Plano de distribución</p>
            </div><div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Plano de Fachadas</p>
            </div>
            <div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Renderización</p>
            </div>
            <div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Presupuesto</p>
            </div>
            <div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Ejecución de Obra.</p>
            </div>
            <p class="pharagrah">Ahora también en la PRIMAVERA, conocemos todas las políticas de diseño y operación dentro de la privada mas Exclusiva de Culiacan.</p>
        </div>
    </div>
    <SocialMenu v-if="!home" source="residencial" />
    <ShortContact />
    <noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=377252243421369&ev=PageView&noscript=1"/></noscript>
</div>
</template>
<script>
import SocialMenu from './SocialMenu';
import ShortContact from './ShortContact';
export default {
    name: 'Conocenos',
    components: {
        SocialMenu,
        ShortContact
    },
    props: ['home'],
    created() {
        // eslint-disable-next-line
        fbq('init', '377252243421369');
        // eslint-disable-next-line
        fbq('track', 'PageView');
    }
}
</script>
<style>
.residencial {
    padding-bottom: 15rem;
    width: 100%;
    min-height: calc( 100vh - 87px);
    background-color: #1f1f1f;
    background-image: url(../assets/img/bg-4.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
}
.residencial .residencial-content-bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 5rem 2rem;
    margin: 0 0 0 auto;
    width: 20rem;
    background-color: #666;
    mix-blend-mode: overlay;
}
.residencial .residencial-content-bg:before {
    content: "";
    background-color: #666;
    position: absolute;
    top: -5rem;
    left: -5rem;
    height: 150%;
    width: 10rem;
    transform: rotate(-5deg);
}
.residencial .residencial-content {
    text-align: left;
    padding: 5rem 2rem;
    margin: 0 0 0 auto;
    width: 30rem;
    mix-blend-mode: screen;
}
.residencial .residencial-title {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
}
.residencial .residencial-title h2 {
    font-size: 3rem;
}
.residencial .residencial-title img {
    width: 3rem;
    margin-left: 1rem;
}
.residencial .residencial-content .pharagrah {
    font-size: .9rem;
    width: 20rem;
    margin: 0 0 0 auto;
}
.residencial .residencial-content p span {
    color: #ccad41;
}
.residencial .residencial-content .with_bullet{
    width: 20rem;
    margin: 1rem 0 0 auto;
    display: flex;
    align-items: flex-start;
    font-size: .8rem;
}
.residencial .residencial-content .with_bullet img {
    width: .5rem;
    margin-right: 1rem;
}
@media screen and (max-width: 600px) {
.residencial .residencial-content {
    width: 100%;
}
.residencial .residencial-title h2 {
    font-size: 2rem;
}
}
</style>