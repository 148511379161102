<template>
<div>
    <div id="espacios" class="desarrollos">
        <div class="desarrollos-content-bg">
        </div>
        <div class="desarrollos-content">
            <div class="desarrollos-title">
                <h2>Desarrollos</h2>
                <img src="@/assets/svg/section_icon_3.svg">
            </div>
            <p class="pharagrah">Nos especializamos en encontrar las mejores zonas habitacionales dentro de la Ciudad.</p>
            <p class="pharagrah">Analizamos aspectos mas importantes como:</p>
            <div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Plusvalía</p>
            </div>
            <div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Seguridad</p>
            </div>
            <div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Accesos rápidos</p>
            </div>
            <div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Entorno actual y futuro</p>
            </div>
            <p class="pharagrah">Con la cantidad y experiencia que nos dan mas de 5mil viviendas entregadas en todo Sinaloa.</p>
            <h2>¿ERES INVERSIONISTA?</h2>
            <p class="pharagrah">Pregunta por nuestras tasas de inversión en proyectos inmobiliarios.</p>
        </div>
    </div>
    <SocialMenu v-if="!home" source="desarrollos" />
    <ShortContact />
    <noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=689183631707398&ev=PageView&noscript=1"/></noscript>
</div>
</template>
<script>
import SocialMenu from './SocialMenu';
import ShortContact from './ShortContact';
export default {
    name: 'Conocenos',
    components: {
        SocialMenu,
        ShortContact
    },
    props: ['home'],
    created() {
        // eslint-disable-next-line
        fbq('init', '689183631707398');
        // eslint-disable-next-line
        fbq('track', 'PageView');
    }
}
</script>
<style>
.desarrollos {
    width: 100%;
    min-height: calc( 100vh - 87px);
    background-color: #1f1f1f;
    background-image: url(../assets/img/bg-3.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
}
.desarrollos .desarrollos-content-bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 5rem 2rem;
    margin: 0 0 0 auto;
    width: 20rem;
    background-color: #5b3180;
    mix-blend-mode: overlay;
}
.desarrollos .desarrollos-content-bg:before {
    content: "";
    background-color: #5b3180;
    position: absolute;
    top: -5rem;
    left: -5rem;
    height: 150%;
    width: 10rem;
    transform: rotate(-5deg);
}
.desarrollos .desarrollos-content {
    text-align: left;
    padding: 5rem 2rem;
    margin: 0 0 0 auto;
    width: 30rem;
    mix-blend-mode: screen;
}
.desarrollos .desarrollos-title {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
}

.desarrollos .desarrollos-content .with_bullet {
    width: 20rem;
    margin: 1rem 0 0 auto;
    display: flex;
    align-items: flex-start;
}
.desarrollos .desarrollos-content .with_bullet img {
    width: .5rem;
    margin-right: 1rem;
}
.desarrollos .desarrollos-title h2 {
    font-size: 3rem;
}
.desarrollos .desarrollos-title img {
    width: 3rem;
    margin-left: 1rem;
}
.desarrollos .desarrollos-content .pharagrah {
    font-size: .9rem;
    width: 20rem;
    margin: 0 0 1rem auto;
}
@media screen and (max-width: 600px) {
.desarrollos .desarrollos-content {
    width: 100%;
}
}
</style>