<template>
  <div>
    <Header />
    <Main />

  </div>
</template>

<script>
import Header from './Header'
import Main from './Main';

export default {
  components: {
    Header,
    Main,
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    // eslint-disable-next-line
    fbq('init', '805876136827030');
    // eslint-disable-next-line
    fbq('track', 'PageView');
  },
}
</script>
