<template>
  <div id="app">
    <Header />
    <router-view />
  </div>
</template>

<script>
import Header from './components/Header'
export default {
  name: 'App',
  components: {
    Header
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  color: #fff;
}
*, :after, :before {
    box-sizing: border-box;
}

.modal-selected .cont {
    text-align: left;
    margin-left: 2rem;
}
#app, #viviend, #espacios, #construccion, #conocenos {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  min-height: calc( 100vh - 86px);
}
</style>
