<template>
    <div>
        <div id="construccion" class="construccion">
            <div class="construccion-content-bg">
        </div>
        <div class="construccion-content">
            <div class="construccion-title">
                <h2>Construcción</h2><img src="@/assets/svg/section_icon_1.svg">
            </div>
            <div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Estructuras Metálicas</p>
            </div
            ><p class="pharagrah">Naves industriales, Bodegas Metalicas, Edificios estructurados, Puentes, Plazas Comerciales, Espectaculares, Tanques Elevados, Tanques de Almacenamiento,Diseño, Cálculo, Supervisión etc.</p>
            <p class="pharagrah">Garantizamos la aplicacion de tus recursos en tu obra sin revolver en otras garantizamos la aplicacion del 100% del anticipo en materiales y enmano de obra</p>
            <div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Obra Civil</p>
            </div>
            <p class="pharagrah">Construcción de carreteras, Construcción de puentes, Construcción de Edificios, Construcción de Viviendas, Remodelaciones en gral, etc.</p>
            <div class="with_bullet">
                <img src="@/assets/svg/bullet_right.svg">
                <p>Urbanización</p>
            </div>
                <p class="pharagrah">Topografía, Líneas de Drenaje, Líneas de Agua Potable, Canales de Riego, Movimiento de Tierra, Mejoramiento de Material, Terracerías, Pavimento, Arbotantes, Luminarias, etc.</p>
                <div class="with_bullet">
                    <img src="@/assets/svg/bullet_right.svg">
                    <p>Estación de Servicio (Gasolinera) Llave en Mano</p>
                </div>
                    <p class="pharagrah">En coordinación don SJS Construcciones expertos en gestora PEMEX, SCT, ASEA, CRE, CNA, SEMARNAT. Proyecto llave en mano incluyendo tienda de conveniencia y otros servicios.</p>
                    <div class="with_bullet">
                        <img src="@/assets/svg/bullet_right.svg">
                        <p>Comercial</p>
                    </div>
                    <p class="pharagrah">Somos Expertos en Diseño de Espacios comerciales, ambientación, Decoración de interiores, Acondicionamiento e Imagen Comercial.</p>
                </div>
                <div class="modal-selected">
                    <carousel :per-page="checkResponsive()" :mouse-drag="true">
                        <slide>
                            <img src="@/assets/img/construccion/A - 01.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/A - 02.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/A - 03.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/A - 04.png" />
                        </slide>

                        <slide>
                            <img src="@/assets/img/construccion/B - 01.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/B - 02.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/B - 03.png" />
                        </slide>
                        <slide>
                        </slide>

                        <slide>
                            <img src="@/assets/img/construccion/C - 01.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/C - 02.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/C - 03.png" />
                        </slide>
                        <slide>
                        </slide>

                        <slide>
                            <img src="@/assets/img/construccion/D - 01.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/D - 02.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/D - 03.png" />
                        </slide>
                        <slide>
                        </slide>

                        <slide>
                            <img src="@/assets/img/construccion/E - 01.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/E - 02.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/E - 03.png" />
                        </slide>
                        <slide>
                        </slide>

                        <slide>
                            <img src="@/assets/img/construccion/F - 01.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/F - 02.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/F - 03.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/F - 04.png" />
                        </slide>

                        <slide>
                            <img src="@/assets/img/construccion/G - 01.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/G - 02.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/G - 03.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/G - 04.png" />
                        </slide>

                        <slide>
                            <img src="@/assets/img/construccion/G - 05.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/G - 06.png" />
                        </slide>
                        <slide>
                        </slide>
                        <slide>
                        </slide>

                        <slide>
                            <img src="@/assets/img/construccion/H - 01.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/H - 02.png" />
                        </slide>
                        <slide>
                            <img src="@/assets/img/construccion/H - 03.png" />
                        </slide>
                        <slide>

                        </slide>
                    </carousel>
                </div>
            </div>
        <SocialMenu v-if="!home" source="construccion" />
        <ShortContact />
        <noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=794038384662522&ev=PageView&noscript=1"/></noscript>
    </div>
</template>
<script>
import SocialMenu from './SocialMenu';
import ShortContact from './ShortContact';
import { Carousel, Slide } from 'vue-carousel';
export default {
    name: 'Construccion',
    components: {
        SocialMenu,
        ShortContact,
        Carousel,
        Slide
    },
    props: ['home'],
    created() {
        window.addEventListener("resize", this.myEventHandler);
        // eslint-disable-next-line
        fbq('init', '794038384662522');
        // eslint-disable-next-line
        fbq('track', 'PageView');
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        myEventHandler() {
           this.$forceUpdate(); 
        },
        checkResponsive() {
           return window.innerWidth <= 900 ? window.innerWidth <= 600 ? 1 : 2 : 4;
        }
    }
}
</script>
<style>
.modal-selected {
    padding: 0 10px !important;
}
.construccion {
    width: 100%;
    min-height: calc( 100vh - 87px);
    background-color: #1f1f1f;
    background-image: url(../assets/img/bg-2.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
}
.construccion .construccion-content-bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 5rem 2rem;
    margin: 0 0 0 auto;
    width: 20rem;
    background-color: #550a12;
    mix-blend-mode: overlay;
}
.construccion .construccion-content-bg:before {
    content: "";
    background-color: #550a12;
    position: absolute;
    top: -5rem;
    left: -5rem;
    height: 150%;
    width: 10rem;
    transform: rotate(-5deg);
}
.construccion .construccion-content {
    text-align: left;
    padding: 5rem 2rem;
    margin: 0 0 0 auto;
    width: 30rem;
    mix-blend-mode: screen;
}
.construccion .construccion-title {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
}
.construccion .construccion-title h2 {
    font-size: 3rem;
}
.construccion .construccion-title img {
    width: 3rem;
    margin-left: 1rem;
}
.construccion .construccion-content .pharagrah {
    font-size: .8rem;
    width: 20rem;
    margin: 0 0 0 auto;
}
.construccion .construccion-content p span {
    color: #ccad41;
}
.construccion .construccion-content .with_bullet {
    width: 20rem;
    margin: 1rem 0 0 auto;
    display: flex;
    align-items: flex-start;
}
.construccion .construccion-content .with_bullet img {
    width: .5rem;
    margin-right: 1rem;
}

.modal-selected, .VueCarousel, .VueCarousel-wrapper{
    width: calc(100vw - 1rem);
}
.modal-selected img {
    width: 20vw;
}
.modal-selected {
    background-color: rgba(85,10,18,.5);
    min-height: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem;
}
.modal-selected .cont {
    text-align: left;
    margin-left: 2rem;
}
.modal-selected .cont p {
    font-size: .7rem;
}
@media screen and (max-width: 900px){
.modal-selected {
    padding: 1rem;
}
.modal-selected {
    padding: 1rem;
}
.modal-selected img {
    width: 50vw;
}
}
@media screen and (max-width: 600px) {
.construccion .construccion-content {
    width: 100%;
}
.modal-selected {
    flex-direction: column;
}
.construccion .construccion-title h2 {
    font-size: 2rem;
}
.modal-selected, .VueCarousel, .VueCarousel-wrapper {
    width: calc(100vw - 10px);
}
.modal-selected img {
    width: 100vw;
}
}


</style>