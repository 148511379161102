<template>
    <div class="page">
        <div id="conocenos" class="conocenos">
            <div class="conocenos-content">
                <p class="pharagrah">aTwork construcción se fundó en 2005, con el objetivo de cubrir la demanda de obra civil en el Estado de Sinaloa y así mismo contribuir con la construcción de la gran oferta de vivienda de todos los niveles que existía en ese momento.</p>
                <p class="pharagrah">En la actualidad en aTwork nos especializamos en 3 rubros donde el común denominador sigue siendo la construcción.</p>
                <div class="with_bullet">
                    <img src="@/assets/svg/bullet_right.svg">
                    <p>Estructura Metalica</p>
                </div>
                <div class="with_bullet">
                    <img src="@/assets/svg/bullet_right.svg">
                    <p>Residencial a la medida</p>
                </div>
                <div class="with_bullet">
                    <img src="@/assets/svg/bullet_right.svg">
                    <p>Desarrollo inmobiliario</p>
                </div>
                <p class="pharagrah">Para cada uno de estos rubros contamos con personal calificado así como todos los equipos y maquinaria necesaria para llevar tu proyecto de 0 a 100 en el plazo pronosticado.</p>
                <h3 class="pharagrah">Garantizamos la aplicación del anticipo al 100% en Materiales y MO.</h3>
                <h3 class="pharagrah">Garantizamos la aplicación de tu inversión a tu obra, sin revolver.</h3>
                <p class="pharagrah">Somos una empresa flexible y nos sabemos adaptar a todos los contratiempos, siempre tenemos la mejor disposición y ponemos todos nuestros recursos a la mano para llegar al objetivo en común.</p>
            </div>
            <div class="clientes">
                <div class="cliente">
                    <img src="@/assets/clientes/ARCO.svg">
                </div>
                <div class="cliente">
                    <img src="@/assets/clientes/coppel.svg">
                </div>
                <div class="cliente">
                    <img src="@/assets/clientes/Culiacan.svg">
                </div>
                <div class="cliente">
                    <img src="@/assets/clientes/Homex.svg">
                </div>
                <div class="cliente">
                    <img src="@/assets/clientes/Ley.svg">
                </div>
                <div class="cliente">
                    <img src="@/assets/clientes/PEMEX.svg">
                </div>
                <div class="cliente">
                    <img src="@/assets/clientes/Sinaloa.svg">
                </div>
                <div class="cliente">
                    <img src="@/assets/clientes/Walmart.svg">
                </div>
            </div>
            </div>
            <SocialMenu v-if="!home" source="nosotros" />
            <ShortContact />
        </div>
</template>
<script>
import SocialMenu from './SocialMenu';
import ShortContact from './ShortContact';
export default {
    name: 'Conocenos',
    components: {
        SocialMenu,
        ShortContact
    }
}
</script>
<style>
.page {
    min-height: 90vh;
    height: 100%;
}
.pharagrah{
    margin: 10px 0;
}
.conocenos {
    padding: 10rem 0;
    width: 100%;
    height: 100%;
    background-color: #1f1f1f;
    background-image: url(../assets/img/bg-5.jpg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}
.conocenos .conocenos-content {
    text-align: center;
    padding: 5rem 2rem;
    /* margin: 0 0 0 auto; */
    width: 50rem;
    max-width: 100vw;
    mix-blend-mode: screen;
}
.conocenos .c-content {
    width: 70%;
    display: flex;
    max-width: 40rem;
    font-size: 1rem;
}
.conocenos .c-content .c-l {
    max-width: 5rem;
    margin-right: 1rem;
}
.conocenos .c-content .c-l img {
    width: 5rem;
}
.conocenos .c-content .c-r {
    border-left: 1px solid #ccad41;
}
.conocenos .c-content .c-r p {
    text-align: justify;
    padding: 0 0 1rem 1rem;
}
.conocenos .c-content .c-r span {
    color: #ccad41;
}
.conocenos .c-content .c-r .location {
    padding: 0 4rem;
    display: flex;
    align-items: flex-start;
}
.conocenos .c-content .c-r .location img {
    width: .5rem;
}
.conocenos .c-content .c-l img {
    width: 5rem;
}
.conocenos .conocenos-content .with_bullet {
    width: 20rem;
    display: flex;
    align-items: flex-start;
    padding-left: 40px;
}

h3.pharagrah {
    padding-left: 130px;
    text-align: left;
}

.conocenos .conocenos-content .with_bullet img {
    width: .5rem;
    margin-right: 1rem;
}
.clientes {
    max-width: 70%;
}
.clientes .cliente{
    margin: 0 auto;
    display: flex;
    width: 10rem;
    height: 10rem;
}
.clientes .cliente img{
    text-align:center;
    display: flex;
    max-width: 10rem;
    max-height: 10rem;
    margin: 0 auto;
}
@media screen and (max-width: 1190px) {
    .conocenos .c-content {
        margin-right: 5rem;
    }
}
</style>
